<template>
  <div class="filter">
    <div class="filter__btn-block">
      <div class="filter__btn-left">
        <div class="filter__btn-left__label">{{ $t('Filter') }}</div>
        <div class="filter__btn-left__reset-btn" @click="resetFilter">
          {{ $t('Reset') }}
        </div>
      </div>
      <div @click="closeLeftSidebar">
        <zem-icon class="cursor-pointer" name="close" size="18" />
      </div>
    </div>
    <div class="filter__dropdown">
      <div class="zem-dropdown mb-0">
        <label class="filter__label" for="date">Регион</label>
        <ZemDropdownList
          v-model="selectedRegion"
          :options="regionsList"
          class="tw-mt-0"
          search
          placeholder="Выбрать"
          @change="handlerFilter('region_id', $event)"
        ></ZemDropdownList>
      </div>
      <div class="zem-dropdown mb-0">
        <label class="filter__label" for="date">Шоссе</label>
        <ZemDropdownList
          v-model="selectedHighway"
          :options="highwaysList"
          class="tw-mt-0"
          search
          placeholder="Выбрать"
          @change="handlerFilter('highway_id', $event)"
        ></ZemDropdownList>
      </div>
      <div class="zem-dropdown mb-0">
        <label class="filter__label" for="date">Удаленность</label>
        <div class="tw-flex tw-gap-x-3">
          <ZemInput v-model="distance.from" placeholder="от" type="number" @input="handlerDistance" />
          <ZemInput v-model="distance.to" placeholder="до" type="number" @input="handlerDistance" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZemDropdownList from './ui/ZemDropdownList'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect'
import ZemIcon from '@/components/ui/ZemIcon'
import ProfileService from '@/services/profile.service'
import OtherService from '@/services/other.service'
import ZemInput from '@/components/ui/ZemInput.vue'

export default {
  name: 'FilterObjectsForm',

  components: {
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ZemIcon,
  },

  data() {
    return {
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      regionsList: [],
      selectedRegion: {
        value: '',
        title: '',
      },
      highwaysList: [],
      selectedHighway: {
        value: '',
        title: '',
      },
      distance: {
        from: 0,
        to: 0,
      },
      filterData: {},
      timer: null,
    }
  },

  mounted() {
    ProfileService.getRegionsList().then(r => {
      this.regionsList = r.data.data
    })
    OtherService.getHighways().then(r => {
      this.highwaysList = r.data.data
    })
  },

  methods: {
    resetFilter() {
      this.$store.commit('objects/getSearch', '')
      this.distance = {
        from: 0,
        to: 0,
      }
      this.selectedRegion = {
        value: '',
        title: '',
      }
      this.selectedHighway = {
        value: '',
        title: '',
      }
      this.$router.push({name: 'objects'})
      this.filterData = {}
      this.getFilter()
    },
    closeLeftSidebar() {
      this.$store.commit('sidebars/changeLeftSidebar', false)
    },
    handlerFilter(field, e) {
      const value = 'title' in e ? e.value : e
      this.filterData[field] = 'title' in e ? e.value : e
      if (!value) delete this.filterData[field]
      this.getFilter()
    },
    handlerDistance() {
      const {from, to} = this.distance
      if (Number(to)) {
        this.filterData['distance_from_mkad'] = `${from}-${to}`
      } else {
        this.filterData['distance_from_mkad'] = `${from}`
      }
      if (!Number(from) && !Number(to)) delete this.filterData['distance_from_mkad']
      this.getFilter()
    },
    getFilter() {
      clearTimeout(this.timer)
      this.timer = null
      this.timer = setTimeout(() => {
        let data = ''
        let counter = 0
        for (let key in this.filterData) {
          data += key + ':' + this.filterData[key]
          counter++
          if (Object.keys(this.filterData).length !== counter) data += ';'
        }
        this.$store.commit('objects/filterMain', data)
        this.$emit('on-update-table')
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter {
  display: block;
  padding: 32px 24px;
  min-width: 192px;

  &__btn-block {
    display: flex;
    justify-content: space-between;
  }

  &__btn-left {
    display: flex;
    align-items: center;

    &__label {
      font-weight: 700;
      font-size: 12px;
      line-height: 13px;
      color: $color-mine-shaft;
    }

    &__reset-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      color: $color-accent;
      text-align: right;
      flex: 1;
      margin-left: 8px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__label {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: $color-gull-gray;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 4px;
  }

  &__dropdown {
    margin-top: 8px;
  }
}

@media screen and (max-width: 1440px) {
  .filter {
    padding: 20px;
    min-width: 152px;
  }
}
</style>
