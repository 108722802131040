<template>
  <div class="map-wrapper">
    <l-map
      v-if="showMap"
      ref="myMap"
      :center="center"
      :zoom="zoom"
    >
      <l-tile-layer
        :attribution="attribution"
        :url="url"
      />

      <l-marker v-for="(coords, index) in coordsItems" :key="index" :lat-lng="coords"/>
    </l-map>
  </div>
</template>

<script>

/* eslint-disable global-require */
import {LIcon, LMap, LMarker, LTileLayer,} from 'vue2-leaflet'
import {icon, Icon, latLng} from 'leaflet'
import 'leaflet/dist/leaflet.css'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('@/assets/icons/Тут разработать UX/24.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  name: 'LeafletMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
  },
  props: {
    coordsItems: Array,
    center: Array,
  },
  data() {
    return {
      showMap: true,
      zoom: 13,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="#">OpenStreetMap</a> contributors',
      icon: icon({
        iconUrl: require('leaflet/dist/images/marker-shadow.png'),
        iconSize: [50, 50],
        iconAnchor: [16, 37],
      }),
    }
  },
  methods: {
    reloadMap() {
      // this.showMap = false
      // this.showMap = true
      // this.$refs.myMap.mapObject.getCenter()
      // const coords = this.$store.state.objects.elements.filter(el => this.$store.state.objects.selectedItemsForDelete.includes(el.id))
      // this.center = latLng(54.654818, 36.644441)
      // this.coordsItems = coords.map(el => (el.coordinates))
    }
  }
}
/* eslint-disable global-require */
</script>

<style lang="scss">
.map-wrapper {
  height: 100%;
  width: 100%;
}

.vue2leaflet-map {
  &.leaflet-container {
    height: 100%;
  }
}

.vue2leaflet-map {
  height: 400px;
  z-index: 1;

  .leaflet-marker-icon {
    width: 45px !important;
    height: 45px !important;
    margin-left: -21px !important;
  }
}

html[dir='rtl'] {
  .leaflet-pane,
  .leaflet-tile,
  .leaflet-marker-icon,
  .leaflet-marker-shadow,
  .leaflet-tile-container,
  .leaflet-pane > svg,
  .leaflet-pane > canvas,
  .leaflet-zoom-box,
  .leaflet-image-layer,
  .leaflet-layer {
    left: 0;
    right: unset;
  }
}
</style>
