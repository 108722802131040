<template>
  <div class="map-wrapper">
    <yandex-map
      :coords="coordsItems[0]"
      :settings="settings"
      :show-all-markers="coordsItems.length > 1"
      :controls="[]"
      :zoom.sync="zoom"
      @click="onClick"
    >
      <ymap-marker
        v-for="(coords, index) in coordsItems"
        :key="index"
        :coords="coords"
        :icon="markerIcon"
        :marker-id="index"
        :balloon-template="balloonTemplate(index)"
      />
    </yandex-map>

    <div class="close-map max-md:tw-top-5 max-md:tw-right-5 max-md:tw-left-auto" @click="$emit('close-map')">
      <XIcon size="20" style="color: #ffffff" />
    </div>
  </div>
</template>

<script>
import {yandexMap, ymapMarker} from 'vue-yandex-maps'
import {XIcon} from 'vue-feather-icons'

export default {
  components: {
    yandexMap,
    ymapMarker,
    XIcon,
  },
  props: {
    coordsItems: Array,
    center: Array,
    objects: Array,
    zoom: Number,
  },
  data() {
    return {
      settings: {
        apiKey: 'ad3a41b6-13be-4101-b96d-55ba0cab372d', // Индивидуальный ключ API
        lang: 'ru_RU', // Используемый язык
        coordorder: 'latlong', // Порядок задания географических координат
        debug: false, // Режим отладки
        version: '2.1', // Версия Я.Карт
      },
      // coords: [
      //   54.82896654088406,
      //   39.831893822753904,
      // ],
      markerIcon: {
        layout: 'default#imageWithContent',
        imageHref: require('@/assets/icons/Тут разработать UX/Vector.png'),
        imageSize: [25, 37.5],
        imageOffset: [-12.5, -37.5],
      },
    }
  },
  methods: {
    balloonTemplate(i) {
      return `
      <div class="zem-collapse-table rows">
        <div class="zem-collapse-table__row" style="grid-template-columns: 1fr 2fr; gap: 5px 10px;">
          <div class="zem-collapse-table__column title mt-1">Регион</div>
          <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
            ${this.objects[i]['region'].data.name}
          </div>
          <div class="zem-collapse-table__column title mt-1">Шоссе</div>
          <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
            ${this.objects[i]['highway'].data.name}
          </div>
          <div class="zem-collapse-table__column title mt-1">Адрес</div>
          <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
            ${this.objects[i]['address']}
          </div>
          <div class="zem-collapse-table__column title mt-1">Координаты</div>
          <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
            ${this.objects[i]['location_lat']}, ${this.objects[i]['location_long']}
          </div>
          <div class="zem-collapse-table__column title mt-1">Удаленность, км.</div>
          <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
            ${this.objects[i]['distance_from_mkad']}
          </div>
        </div>
      </div>
      `
    },
    onClick(e) {
      // this.coords = e.get('coords');
    },
  },
}
</script>

<style lang="scss">
.ymap-container,
.map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .ymaps-2-1-79-balloon {
    border-radius: 6px;

    .ymaps-2-1-79-balloon__layout {
      border-radius: 6px;

      .ymaps-2-1-79-balloon__close {
        margin-right: 5px;
      }
    }
  }
}

.close-map {
  position: absolute;
  background: rgb(13, 178, 178);
  top: calc(50% - 16px);
  width: 32px;
  height: 32px;
  left: -16px;
  border-radius: 50%;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 2px 4px 8px rgb(19 19 19 / 31%);
  }
}
</style>